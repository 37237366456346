import { getHomeBanner, getExternalList } from '../api/index.js';

export default {
  data() {
    return {
      friendLink: [],
      // banners: [],
    }
  },
  created() {

    // this.getBanners();
    this.getFriendLink();
  },
  methods: {
    // 滑动到顶部
    scrollTop() {
      this.scrollInto()
    },
    // 平滑滚动到指定ref元素顶端, 默认ref="body"元素
    scrollInto(e, ref = 'body') {
      this.$refs[ref].scrollIntoView({ behavior: "smooth" });
    },
    // 获取banner
    // getBanners() {
    //   getHomeBanner({bannerType: 'WEBSITE'}).then(res => {
    //     if (res.resultStatus) {
    //       this.banners = res.resultData;
    //     }
    //   })
    // },
    // banner链接跳转
    urlLink(url) {
      url && (window.location = url);
    },
    // 获取友情链接列表
    getFriendLink() {
      getExternalList().then(res => {
        let list = res.resultData || [];
        this.friendLink = list;
      })
    },
  }
}
